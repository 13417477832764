import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What skills I have.</h5>
      <h2>Skills Expertisse</h2>
      <div className="experience__levels">
        <span>1 - Basic</span>
        <span>2 - Novice</span>
        <span>3 - Intermediate</span>
        <span>4 - Advanced </span>
        <span>5 - Expert</span>
      </div>
      <div className="container experience__container">
        <div className="experience__tools">
          {/* <h3>Experience</h3> */}
          <div className="experience__content">
            <article className='experience__details'>
              <h4><BsPatchCheckFill className='experience__details-icons' /> Programming languages </h4>
              <small className='text-light'>Python - 4</small>
              <div className="progress__bar python"></div>
              <small className='text-light'>Java - 2</small>
              <div className="progress__bar java"></div>
            </article>

            <article className='experience__details'>
              <h4><BsPatchCheckFill className='experience__details-icons' /> Data Visualization </h4>
              <small className='text-light'>Matplotlib, Seaborn, Plotly - 4</small>
              <div className="progress__bar python__visual"></div>
              <small className='text-light'>Power Bi - 2</small>
              <div className="progress__bar power__bi"></div>
            </article>

            <article className='experience__details'>
              <h4><BsPatchCheckFill className='experience__details-icons' /> Machine Learning</h4>
              <small className='text-light'>SciKit-Learn - 4</small>
              <div className="progress__bar scikit"></div>
              <small className='text-light'>TensorFlow - 3</small>
              <div className="progress__bar tensorflow"></div>
            </article>

            <article className='experience__details'>
              <h4><BsPatchCheckFill className='experience__details-icons' /> Database</h4>
              <small className='text-light'>SQL - 4</small>
              <div className="progress__bar sql"></div>
            </article>

            <article className='experience__details'>
              <h4><BsPatchCheckFill className='experience__details-icons' /> AWS</h4>
              <small className='text-light'>EC2, S3, Glue, EMR, StepFunctions, Lambda, etc - 3.5</small>
              <div className="progress__bar aws"></div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience