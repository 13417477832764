import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='headerSocials'>
      <a href="https://www.linkedin.com/in/matthewrip/" target="_blank" rel="noopener noreferrer"><BsLinkedin size={20} /></a>
      <a href="https://github.com/MatthewRip" target="_blank" rel="noopener noreferrer"><BsGithub size={20} /></a>

    </div>
  )
}

export default HeaderSocials