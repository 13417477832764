import React, { useEffect, useRef } from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/whoeyes.png'
import HeaderSocial from './HeaderSocials'
import { init } from 'ityped'

const Header = () => {

  // ityped text effect
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1000,
      loop: true,
      strings: ['Data Analysis', 'Data Engineering', 'Data Science', 'Machine Learning']
    })
  }, [])


  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Matthew Rip</h1>
        <h5 className="text-light"><span ref={textRef}></span></h5>
        <CTA />
        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="" />
        </div>

        <a href="#about" className='scroll__down'>Scroll Down</a>

      </div>
    </header>
  )
}

export default Header