import React from 'react'
import './portfolio.css'
import { featuredPortfolio } from '../../data'


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          featuredPortfolio.map(({ id, img, title, tools, github, demo }) => {
            return (
              <article key={id} className='portfolio__item' >
                <div className="portfolio__item-img">
                  <img src={img} alt={title} />
                </div>
                <h3>{title}</h3>
                <h5 className='tools__used'>{tools}</h5>
                <div className="portfolio__item-cta">
                  <a href={github} className='btn' target='_blank' rel="noopener noreferrer">Github</a>
                  {demo !== "" &&
                    <a href={demo} className='btn btn-primary' target='_blank' rel="noopener noreferrer">Demo</a>
                  }
                </div>
              </article>
            )
          })
        }


      </div>

    </section >
  )
}

export default Portfolio