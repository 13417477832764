import React from 'react'
import './about.css'
import Me from '../../assets/whozoom.jpg'
import { FaAward } from 'react-icons/fa'
// import { HiOutlineUserGroup } from 'react-icons/hi'
import { VscFolder } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-img">
            <img src={Me} alt="" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>1+ year</small>
            </article>
            <article className='about__card'>
              <VscFolder className='about__icon' />
              <h5>Projects</h5>
              <small>A number &#62; 1</small>
            </article>
            {/* <article className='about__card'>
              <HiOutlineUserGroup className='about__icon' />
              <h5>Clients</h5>
              <small>sadge</small>
            </article> */}
          </div>
          <p>
            I am Matthew Rip, I am an Graduate of <a href="https://explore-datascience.net/" target="_blank" rel="noopener noreferrer" className='explore__ref'>Explore Data Science Academy.</a>
            &nbsp;Where I have learnt skills such as SQL, Python, Data Visualization, Supervised and unsupervised machine learning. I have also completed course for Java and Andriod
            development on <a href="https://www.udemy.com/" target="_blank" rel="noopener noreferrer" className='explore__ref'>Udemy</a> and multiple other data related courses from
            <a href="https://www.datacamp.com/" target="_blank" rel="noopener noreferrer" className='explore__ref'> datacamp.</a>

          </p>
          <a href="#contact" className='btn btn-primary'>Contact me :)</a>
        </div>

      </div>

    </section>
  )
}

export default About